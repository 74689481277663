import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { withDefaultApexChart } from '../../../../../hoc';
import { chartsMaxYTick } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const defaultSeries = [
    {
        name: '',
        data: [],
    },
];

const KxChart = ({
    dataToChart: { soilParameters = [] },
    chartOptions = {},
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState(defaultSeries);

    useDeepCompareEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...soilParameters.reduce(
                            (acc, ele, index, arr) => {
                                if (index === arr.length - 1) {
                                    return [
                                        ...acc,
                                        [ele.kx_1, ele.z - ele.h],
                                        [ele.kx_2, ele.z],
                                        [0, ele.z],
                                    ];
                                } else {
                                    return [
                                        ...acc,
                                        [ele.kx_1, ele.z - ele.h],
                                        [ele.kx_2, ele.z],
                                    ];
                                }
                            },
                            [],
                        ),
                    ],
                },
            ];
        });
        setOptions(() => {
            const maxZ = Math.max(...soilParameters.map((ele) => ele.z));
            const { maxNum, maxTick } = chartsMaxYTick(maxZ);

            return {
                ...chartOptions,
                yaxis: {
                    ...chartOptions['yaxis'],
                    type: 'numeric',
                    tickAmount: maxTick,
                    max: maxNum,
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max:
                        1.1 *
                        Math.max(
                            ...soilParameters.reduce(
                                (acc, ele) => [...acc, ele.kx_1, ele.kx_2],
                                [],
                            ),
                        ),
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#333'],
                    opacity: 0.25,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    width: 1,
                    colors: ['#666'],
                },
            };
        });
    }, [soilParameters, chartOptions]);

    return (
        <Chart
            options={options}
            series={series}
            type="area"
            height={'350px'}
            width={'100%'}
        />
    );
};

export default React.memo(
    withDefaultApexChart(KxChart),
    (prev, next) => stringify(prev) === stringify(next),
);
