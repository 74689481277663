import React, { createContext } from 'react';
import SaveProjectService from '../../services/SaveProject';

import { 
    SoilTableService,
    DefaultSoilService,
    SoilProfileService,
    PilePropertiesService,
    CalculationSettingsService,
    SaftyFactorsService,
    ProjectTitleService,
    FoundationParametersService,
    SubmitCalculationsService, 
    NewProjectService, 
    ResultsService,
    LoadingService, 
    GlobalAlertService,
    EditProjectService,
    PileLoadsService,
    SaveSoilService,
    EditSoilService, 
    PrintResultsService,
    FileNameToSave,
    LoadAndRemoveProjectService
} from '../../services/HorizontalCapacity';

const dataContext = createContext({});

const HorizontalCapacityProvider = ({ children }) => {
    const { globalAlerts }          = GlobalAlertService();
    const { globalLoading }         = LoadingService();
    const { fileNameToSave }        = FileNameToSave();
    const soilProfileFileNameToSave = FileNameToSave();

    const { results }               = ResultsService();

    const { foundationParameters }  = FoundationParametersService({
        updateResultsState: results.updateState,
    });
    const { pileProperties }        = PilePropertiesService({ 
        perpendicularSpace: foundationParameters.state.perpendicularSpace, 
        parallelSpace: foundationParameters.state.parallelSpace, 
        updateFoundationState: foundationParameters.updateState,
        updateResultsState: results.updateState
    });
    const { soilTable }             = SoilTableService({
        updatePileProperties: pileProperties.updateState,
        updateResultsState: results.updateState
    }); 
    const { defaultSoilLayer }      = DefaultSoilService(soilTable.updateState);    
    const { calculationSettings }   = CalculationSettingsService({
        updateResultsState: results.updateState
    });

    const { saftyFactors }          = SaftyFactorsService({
        soilsCollection: soilTable.state, 
        pileParameters: pileProperties.state, 
        foundationParameters: foundationParameters.state, 
        calculationsSettings: calculationSettings.state,

        updateResultsState: results.updateState
    });
    const { projectTitle }          = ProjectTitleService();
    const { soilProfile }           = SoilProfileService(soilTable.state);
    const { pileLoads }             = PileLoadsService({
        updateResultsState: results.updateState
    });
 
    const { submitCalculation }     = SubmitCalculationsService({
        soilTable: { state: soilTable.state, updateState: soilTable.updateState },
        pileProperties: { state: pileProperties.state, updateState: pileProperties.updateState },
        calculationSettings: { state: calculationSettings.state, updateState: calculationSettings.updateState },
        foundationParameters: { state: foundationParameters.state, updateState: foundationParameters.updateState },
        saftyFactors: { state: saftyFactors.state, updateState: saftyFactors.updateState },
        pileLoads:{ state: pileLoads.state, updateState: pileLoads.updateState },
        results: { updateState: results.updateState },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    });
    const { editSoilProfile } = EditSoilService({ 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        updateSoilTable: soilTable.onLoad,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        fetch: {
            getProfiles: {
                url: '/api/soils/parameters/profiles',
                method: 'GET'
            },
            getProjectsName: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            removeProfile: {
                url: '/api/soils/parameters/edit',
                method: 'DELETE'
            },
            loadProfile: {
                url: '/api/soils/parameters/edit',
                method: 'GET'
            }
        }
    });

    const { loadAndRemoveProject } = LoadAndRemoveProjectService({
        profileStateChanged: editSoilProfile.state.stateChanged,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnRemove: '/api/kosecki-method/project',
        urlOnLoadCalculations: '/api/kosecki-method/projects'
    });
    
    const { editProject } = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        updateFoundationParameters: foundationParameters.updateState,
        updatePileProperties: pileProperties.updateState,
        updateSoilTable: soilTable.updateState,
        updateColculationSettings: calculationSettings.updateState,
        updateSaftyFactors: saftyFactors.updateState,
        updateTitle: projectTitle.updateState, 
        updatePileLoads: pileLoads.updateState,
        updateResults: results.updateState,

        onLoadFileName: fileNameToSave.onLoad,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 

        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,
        onSetSoilProfileMainProjectName: editSoilProfile.onSetMainProjectName,
 
        projectId: loadAndRemoveProject.state.currentProjectId,
    });

    const { saveSoilProfile } = SaveSoilService({ 
        fileNameToSave: soilProfileFileNameToSave.fileNameToSave.fileName,
        initMainProjectName: loadAndRemoveProject.state.mainProjectName,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        onChangeState: editSoilProfile.onStateChange,
        model: {
            soilsCollection: soilTable.state
        },
        fetch: {
            projectList: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            saveSoil: {
                url: '/api/soils/parameters/edit',
                method: 'POST'
            },
            editSoil: {
                url: '/api/soils/parameters/edit',
                method: 'PUT'
            },
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading 
    });

    const { saveProject } = SaveProjectService({
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,
        
        currentProjectId: editProject.currentProjectId,
        currentProfileId: editProject.currentProfileId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
        
        model: {
            soilsCollection: soilTable.state, 
            pileParameters: pileProperties.state, 
            foundationParameters: foundationParameters.state, 
            calculationsSettings: calculationSettings.state,
            saftyFactors: saftyFactors.state,   
            pileLoads: pileLoads.state
        },
        results: {
            designSoilParameters: results.state.designSoilParameters,
            internalForces: results.state.internalForces,
            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
            parametersDetails: results.state.parametersDetails,
            restParameters: results.state.restParameters,
            currentCapacity: results.state.summaryResults
        },
        fetch: {
            save: {
                url: '/api/kosecki-method/saveProject',
                method: 'POST'
            },
            overwrite: {
                url: '/api/kosecki-method/saveProject',
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,
        onProjectStateChange: saveSoilProfile.onProjectStateChange,
        onProfileStateChange: saveSoilProfile.onProfileStateChange,
        profileStateChange: saveSoilProfile.profileStateChange,
        removeProfileStateChange: editSoilProfile.state.stateChanged,

        saveProjectToLocalStorage: (str) => localStorage.setItem('kx', str)
    });

    const { printResults } = PrintResultsService({
        projectTitle: projectTitle,
        soilsCollection: soilTable.state, 
        pileParameters: pileProperties.state, 
        foundationParameters: foundationParameters.state, 
        calculationsSettings: calculationSettings.state,
        saftyFactors: saftyFactors.state,
        pileLoads: pileLoads.state,
        results: {
            ...results.state,
        }
    });
 
    const { newProject } = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad,
        initProject: [
            () => localStorage.removeItem('kx'),
            globalAlerts.initState,
            globalLoading.initState,
            foundationParameters.initState,
            pileProperties.initState,
            soilTable.initState,
            defaultSoilLayer.initState,
            calculationSettings.initState,
            saftyFactors.initState,
            projectTitle.initState,
            pileLoads.initState,

            results.initState,
            printResults.initState
        ]
    });

    return (
        <dataContext.Provider 
            value={{ 
                soilTable, 
                defaultSoilLayer, 
                newProject,
                soilProfile, 
                projectTitle, 
                calculationSettings, 
                saftyFactors, 
                pileProperties,  
                foundationParameters, 
                submitCalculation, 
                globalAlerts,
                globalLoading,
                saveProject,
                editProject,
                results,
                saveSoilProfile,
                editSoilProfile,
                printResults,
                pileLoads,
                fileNameToSave,
                loadAndRemoveProject
            }}
        >
            {children}
        </dataContext.Provider>
    )
}

export { dataContext }
export default HorizontalCapacityProvider