import { Fragment, useState } from 'react';
import {
    Modal,
    SectionTitle,
    Table,
    Pagination,
    ItemsPerPage,
    DatePickerComponent,
} from '../../../components';
import styles from './index.module.css';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { withPagination } from '../../../hoc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDeepCompareEffect from 'use-deep-compare-effect';
import DownloadButton from '../Invoice';
import { Row, Col } from 'react-bootstrap'; 

const PaymentsHistory = ({
    dataToPaginate,
    allCollection,
    pagination,
    paginationHelpers,
    startDate,
    endDate,
    onChangeDateRange,
    onClearDateInput,
    onChangeInvoiceStatus,
}) => {
    const [csvHeaders, setCsvHeaders] = useState([]);
    const [csvData, setCsvData] = useState([]);

    const [transDetails, setTransDetails] = useState({});
    const [open, setOpen] = useState(false);

    const onClickTransDetails = ({ open, details = {} }) => {
        setTransDetails(details);
        setOpen(open);
    };
    const onHide = () => setOpen(false);

    useDeepCompareEffect(() => {
        setCsvData(
            allCollection
                .map(
                    (
                        { buyer, price, user, transactionDate, transId },
                        index,
                        arr,
                    ) => ({
                        firstName: buyer.firstName,
                        lastName: buyer.lastName,
                        email: buyer.email,
                        amount: price,
                        transactionDate: moment(transactionDate)
                            .local('pl')
                            .format('DD.MM.YYYY, HH:mm'),
                        summary: arr
                            .filter((_, idx) => idx <= index)
                            .reduce((acc, ele) => acc + Number(ele.price), 0),
                        user:
                            user === 'user'
                                ? 'Subskrypcja własna'
                                : user === 'subusers'
                                ? 'Subskrypcja użytkowników'
                                : '',
                        transId: transId ? transId : '',
                    }),
                )
                .map((ele) =>
                    Object.fromEntries(
                        Object.entries(ele).map(([key, value = '']) => [
                            [key],
                            value.toString().replace('.', ','),
                        ]),
                    ),
                ),
        );

        setCsvHeaders([
            {
                label: 'Imię autora transakcji',
                key: 'firstName',
            },
            {
                label: 'Nazwisko autora transakcji',
                key: 'lastName',
            },
            {
                label: 'Adres email autora transakcji',
                key: 'email',
            },
            {
                label: 'ID transakcji',
                key: 'transId',
            },
            {
                label: 'Rodzaj zakupu',
                key: 'user',
            },
            {
                label: 'Data transakcji',
                key: 'transactionDate',
            },
            {
                label: 'Kwota transakcji',
                key: 'amount',
            },
            {
                label: 'Suma',
                key: 'summary',
            },
        ]);
    }, [allCollection]);

    return (
        <Fragment>
            <div className={styles.flexSpace}>
                <SectionTitle title="Historia płatności" />
                <ItemsPerPage
                    onDisplayItemsNumber={paginationHelpers.displayItemsNumber}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    display={[10, 25, 50, paginationHelpers.allItemsLength]}
                />
            </div>
            <Table
                csvData={csvData}
                csvFilename={'PaymentsReport'}
                csvHeaders={csvHeaders}
                className={styles.table}
                filterByDate={DatePickerComponent({
                    startDate,
                    endDate,
                    onChangeDateRange,
                    onClearDateInput,
                    onChangeInvoiceStatus,
                })}>
                <thead>
                    <tr>
                        <th>Nr</th>
                        <th title="Produkt">Produkt</th>
                        <th title="Kwota transakcji netto">Kwota netto</th>
                        <th title="Podatek vat">VAT</th>
                        <th title="Kwota transakcji brutto">Kwota brutto</th>
                        <th title="Data transakcji">Data transakcji</th>
                        <th title="Szczegóły transakcji">Uwagi</th>
                        <th title="Numer faktury">Nr faktury</th>
                        <th title='Faktury'>Faktury</th>
                    </tr>
                </thead>

                {dataToPaginate.length > 0 ? (
                    <tbody>
                        {dataToPaginate.map((element, index, arr) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        {paginationHelpers.countingItemsPerPage + index + 1}.
                                    </td>
                                    <td>{`${
                                        element.type === 1
                                            ? 'Nowy użytkownik'
                                        : element.type === 2
                                            ? 'Przedłużenie subskrypcji'
                                            : ''
                                    }`}</td>
                                    <td>{element.netPrice}</td>
                                    <td>{element.vat > 0 ? element.vat : '-'}</td>
                                    <td>{element.grossPrice}</td>
                                    <td>{moment(element.transactionDate).local('pl').format('DD.MM.YYYY, HH:mm')}</td>
                                    <td className={styles.pointer}>
                                        <FontAwesomeIcon
                                            onClick={() =>
                                                onClickTransDetails({
                                                    open: true,
                                                    details: {
                                                        firstName: element.buyer.firstName,
                                                        lastName: element.buyer.lastName,
                                                        email: element.buyer.email,
                                                        netAmount: element.netPrice,
                                                        vat: element.vat,
                                                        grossAmount: element.grossPrice,
                                                        transId: element.invoiceId,
                                                        products: element.products,
                                                    },
                                                }) 
                                            }
                                            icon={faQuestionCircle}
                                        />
                                    </td>
                                    <td>{element.invoiceNumber}</td>
                                    <td width={150}>
                                        {element.invoiceNumber ? (
                                            <DownloadButton 
                                                invoice={element}
                                            />
                                        ) : 
                                        <Fragment>Brak faktury</Fragment>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                ) : (
                    <tbody>
                        <tr>
                            <td className={styles.tdNoBorder}>
                                <div className={styles.absoluteTr}>
                                    <p className={styles.noUsers}>
                                        Brak wykonanych transakcji
                                    </p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                )}
            </Table>
            {paginationHelpers.totalPages > 1 && (
                <Pagination
                    currentPage={pagination.currentPage}
                    itemsPerPage={pagination.itemsPerPage}
                    totalItems={paginationHelpers.allItemsLength}
                    nextPage={paginationHelpers.nextPage}
                />
            )}

            <Modal
                css={styles.modalOverflow}
                cssDialog={styles.modal}
                onHide={onHide}
                title="Szczegóły dotyczące transakcji"
                open={open}>
                <Row>
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>
                                Identyfikator transakcji
                            </p>
                            <p>{transDetails.transId || 'brak'}</p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>Autor transakcji</p>
                            <p>{`${transDetails.firstName} ${transDetails.lastName}, ${transDetails.email}`}</p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>Kwota transakcji netto</p>
                            <p>{transDetails.netAmount} zł</p>
                        </div>
                    </Col>
                    {
                        transDetails.vat > 0 &&
                        <Col xs={12}>
                            <div className={styles.detail}>
                                <p className={styles.upper}>Kwota podatku VAT</p>
                                <p>{transDetails.vat} zł</p>
                            </div>
                        </Col>                        
                    }
                    <Col xs={12}>
                        <div className={styles.detail}>
                            <p className={styles.upper}>Kwota transakcji brutto</p>
                            <p>{transDetails.grossAmount} zł</p>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <p className={styles.upper}>Szczegóły transakcji</p>
                    </Col>
                    {Array.isArray(transDetails.products) &&
                    transDetails.products.length > 0 ? (

                        <Table className={styles.table1}>
                            <thead>
                                <tr>
                                    <th>Nr</th>
                                    <th title="Imię użytkownika">Imię</th>
                                    <th title="Nazwisko użytkownika">Nazwisko</th>
                                    <th title="Adres email">Email</th>
                                    <th title="Typ subskrypcji">Subskrypcja</th>
                                    <th title="Kwota transakcji">Cena</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transDetails.products.map((ele, idx) => {
                                    return (
                                        <tr key={idx}>
                                            <td>{idx + 1}</td>
                                            <td>{ele.user.firstName}</td>
                                            <td>{ele.user.lastName}</td>
                                            <td>{ele.user.email}</td>
                                            <td>
                                                {ele.plan === 1
                                                    ? 'Subskrypcja miesięczna'
                                                    : ele.plan === 2
                                                    ? 'Subskrypcja roczna'
                                                    : ele.plan === 3
                                                    ? 'Subskrypcja testowa'
                                                    : ''}
                                            </td>
                                            <td>{ele.netPrice} zł</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                        
                    ) : (
                        <Col xs={12}>
                            <div className={styles.detail}>
                                <p className={styles.upper}>
                                    Rodzaj subskrypcji
                                </p>
                                <p>
                                    {transDetails.plan === 1
                                        ? 'Subskrypcja miesięczna'
                                        : transDetails.plan === 2
                                        ? 'Subskrypcja roczna'
                                        : transDetails.plan === 3
                                        ? 'Subskrypcja testowa'
                                        : ''}
                                </p>
                            </div>
                        </Col>
                    )}
                </Row>
            </Modal> 
        </Fragment>
    );
};

export default withPagination({
    component: PaymentsHistory,
    returnAllCollection: true,
    paginationState: {
        currentPage: 1,
        itemsPerPage: 15,
    },
});