import React, { createContext } from 'react';
import { 
    LoadingService, 
    GlobalAlertService,
    FileNameToSave,
    SaveProjectService,
    NewProjectService,
    LoadAndRemoveProjectService,
    ProjectTitleService,

    CalculationsService,
    SubmitCalculationsService,

    ResultsService,
    PrintResultsService,
    EditProjectService,
} from '../../services/Waling'; 
 
const walingContext = createContext({});

const WalingProvider = ({ children }) => {
    const { globalAlerts }          = GlobalAlertService();
    const { globalLoading }         = LoadingService();
    const { fileNameToSave }        = FileNameToSave(); 
    const { projectTitle }          = ProjectTitleService(); 
    const { results }               = ResultsService()  
    const { calculationState }      = CalculationsService({
        updateResultsState: results.updateState,
    });
    const { submitCalculation } = SubmitCalculationsService({
        calculations: { state: calculationState.state, updateState: calculationState.updateState },
        results: { 
            updateState: results.updateState,
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading 
    });

    const { loadAndRemoveProject } = LoadAndRemoveProjectService({ 
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnRemove: '/api/waling/project',
        urlOnLoadCalculations: '/api/waling/projects'
    });
    const { printResults } = PrintResultsService({
        projectTitle: projectTitle.state, 
        calculations: calculationState.state,
        results: {
            ...results.state,
        }
    });
    const { editProject } = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        loadCalculationState: calculationState.loadProject,
        loadTitle: projectTitle.loadProject,
        loadResults: results.loadProject,

        onLoadFileName: fileNameToSave.onLoad,

        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,

        initProject: () => {
            calculationState.initState();
            results.initState();
            printResults.initState();
        },

        projectId: loadAndRemoveProject.state.currentProjectId
    });
    const { saveProject } = SaveProjectService({
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,

        currentProjectId: editProject.currentProjectId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
         
        model: { 
            calculations: calculationState.state,
        },
        results: { 
            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
            results: results.state 
        },

        fetch: {
            save: {
                url: '/api/waling/saveProject',
                method: 'POST'
            }, 
            overwrite: { 
                url: '/api/waling/saveProject', 
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/cpt/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,

        saveProjectToLocalStorage: (str) => localStorage.setItem('waling', str)
    });
    const { newProject } = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        initProject: [
            () => localStorage.removeItem('waling'),
            globalAlerts.initState,
            globalLoading.initState,
            projectTitle.initState,
            calculationState.initState,
            results.initState,
            printResults.initState
        ]
    });

    return (
        <walingContext.Provider 
            value={{ 
                globalAlerts,
                globalLoading,
                fileNameToSave,
                newProject,

                editProject,
                loadAndRemoveProject,
                printResults,
                projectTitle,
                saveProject,
                calculationState,
                submitCalculation,
                results
            }}
        >
            {children}
        </walingContext.Provider>
    )
}

export { walingContext }
export default WalingProvider;