import React, { createContext } from 'react';
import { 
    ProjectTitleService,
    GlobalAlertService, 
    GlobalLoadingService,
    SoilProfileService, 
    SoilTableService,
    MicropilePropertiesService,
    FileNameToSave,
    NewProjectService,
    ZwgLevelService,
    SaveSoilService,
    EditSoilService,
    SaftyFactorsService,
    SubmitCalculationsService,
    SaveProjectService,
    EditProjectService,
    ResultsService,
    LoadAndRemoveProjectService, 
    PrintResultsService
} from '../../services/MicropilesCompression'; 
  
const micropilesCompressionContext = createContext({});

const MicropilesCompressionProvider = ({ children }) => { 
    
    const { projectTitle }              = ProjectTitleService(); 
 
    const { globalAlerts }              = GlobalAlertService();
    const { globalLoading }             = GlobalLoadingService(); 
    const { fileNameToSave }            = FileNameToSave();
    const soilProfileFileNameToSave     = FileNameToSave();
    const { results }                   = ResultsService();
    const { zwgLevel }                  = ZwgLevelService({
        updateResultsState: results.updateState
    }); 
    const { micropileProperties }       = MicropilePropertiesService({
        updateResultsState: results.updateState,
    }); 
    const { soilTable }                 = SoilTableService({
        updateMicropileElevation: micropileProperties.updateState,
        updateResultsState: results.updateState,
    }); 
    const { editSoilProfile }           = EditSoilService({
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        updateSoilTable: soilTable.onLoad, 
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        fetch: {
            getProfiles: {
                url: '/api/soils/parameters/profiles',
                method: 'GET'
            },
            getProjectsName: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            removeProfile: {
                url: '/api/soils/parameters/edit',
                method: 'DELETE'
            },
            loadProfile: {
                url: '/api/soils/parameters/edit',
                method: 'GET'
            }
        }
    });
    const { soilProfile }               = SoilProfileService({
        soilTableState: soilTable.state,
    });
    const { saftyFactors }              = SaftyFactorsService({
        micropileType: micropileProperties.state.micropileType,
        axisLoad: micropileProperties.state.axisLoad,
        updateRods: micropileProperties.updateRods,
        updateMicropileProperties: micropileProperties.updateState,
        updateResultsState: results.updateState,
    });
    const { submitCalculation }         = SubmitCalculationsService({
        soilTable: { state: soilTable.state, updateState: soilTable.updateState },
        micropileProperties: { state: micropileProperties.state, updateState: micropileProperties.updateState },
        zwgLevel: { state: zwgLevel.state, updateState: zwgLevel.updateState },
        saftyFactors: { state: saftyFactors.state, updateState: saftyFactors.updateState },
        results: { 
            updateState: results.updateState,
            updateCurrentMicropileLength: results.onChangeCurrentMicropileLength,
            onChangeProdMicropileLength: results.onChangeProdMicropileLength
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    });
    const { loadAndRemoveProject }      = LoadAndRemoveProjectService({
        profileStateChanged: editSoilProfile.state.stateChanged,
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        urlOnLoad: '/api/projects/list/names',
        urlOnLoadCalculations: '/api/micropileCompression/projects',
        urlOnRemove: '/api/micropileCompression/calculations'
    });
    const { editProject }               = EditProjectService({
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,

        loadMicropileProperties: micropileProperties.loadProject,
        loadSoilTable: soilTable.onLoad,
        loadZwgLevel: zwgLevel.loadProject, 
        loadSaftyFactors: saftyFactors.loadProject,
        loadTitle: projectTitle.loadProject,
        loadResults: results.loadProject,

        onLoadFileName: fileNameToSave.onLoad,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 

        onSetMainProjectName: loadAndRemoveProject.onSetMainProjectName,
        onSetSoilProfileMainProjectName: editSoilProfile.onSetMainProjectName,

        projectId: loadAndRemoveProject.state.currentProjectId
    }); 
    const { saveSoilProfile }           = SaveSoilService({
        fileNameToSave: soilProfileFileNameToSave.fileNameToSave.fileName,
        initMainProjectName: loadAndRemoveProject.state.mainProjectName,
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        onChangeState: editSoilProfile.onStateChange,
        model: {
            soilsCollection: soilTable.state 
        },
        fetch: {
            projectList: {
                url: '/api/projects/list/names',
                method: 'GET'
            },
            saveSoil: {
                url: '/api/soils/parameters/edit',
                method: 'POST'
            },
            editSoil: {
                url: '/api/soils/parameters/edit',
                method: 'PUT'
            },
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading
    });
    const { saveProject }               = SaveProjectService({ 
        fileNameToSave: fileNameToSave.fileName,
        onLoadProjectName: fileNameToSave.onLoad, 
        projectTitle: projectTitle.state,

        currentProjectId: editProject.currentProjectId,
        currentProfileId: editProject.currentProfileId,
        currentUserEmail: loadAndRemoveProject.state.currentUser,
        loadProjectState: editProject.loadProjectState,
        
        model: {
            soilsCollection: soilTable.stateToSave,
            micropileParameters: micropileProperties.stateToSave, 
            zwgLevel: zwgLevel.state,
            saftyFactors: saftyFactors.state,
        },
        results: {
            isResultsActive: results.isResultsActive,
            isResultsActual: results.state.isResultsActual,
            results: results.stateToSave,
            currentMicropileLength: results.currentMicropileLength,
            currentCapacity: results.currentCapacity,
        },
        fetch: {
            save: {
                url: '/api/micropileCompression/calculations/saveProject',
                method: 'POST'
            },
            overwrite: {
                url: '/api/micropileCompression/calculations/saveProject',
                method: 'PUT'
            },
            create: {
                url: '/api/projects/create',
                method: 'POST'
            },
            projectList: {
                url: '/api/projects/list',
                method: 'GET'
            }
        },
        setAlert: globalAlerts.addAlert,
        setLoading: globalLoading.addLoading,
        onChangeState: loadAndRemoveProject.onStateChange,
        onProjectStateChange: saveSoilProfile.onProjectStateChange,
        onProfileStateChange: saveSoilProfile.onProfileStateChange,
        profileStateChange: saveSoilProfile.profileStateChange,
        removeProfileStateChange: editSoilProfile.state.stateChanged,

        saveProjectToLocalStorage: (str) => localStorage.setItem('compression', str)
    });
    const { printResults }              = PrintResultsService({
        projectTitle: projectTitle.state,
        currentMicropileLength: results.currentMicropileLength,
        results: {
            ...results.state, 
        }
    });
    const { newProject }                = NewProjectService({
        onLoadProjectName: fileNameToSave.onLoad, 
        onLoadProfileName: soilProfileFileNameToSave.fileNameToSave.onLoad, 
        initProject: [
            () => localStorage.removeItem('compression'),
            globalAlerts.initState,
            globalLoading.initState,  
            projectTitle.initState,                      
            micropileProperties.initState,
            zwgLevel.initState,
            saftyFactors.initState,
            soilTable.initState,
            results.initState,
            printResults.initState
        ]
    });

    return ( 
        <micropilesCompressionContext.Provider  
            value={{ 
                projectTitle,
                globalAlerts,
                globalLoading,
                soilTable,
                soilProfile,
                fileNameToSave,
                newProject,
                loadAndRemoveProject,
                saveProject,
                editProject,
                micropileProperties,
                zwgLevel,
                saveSoilProfile,
                editSoilProfile,
                saftyFactors,
                submitCalculation,
                results,
                printResults
            }}
        >
            {children}
        </micropilesCompressionContext.Provider>
    )
}

export { micropilesCompressionContext }
export default MicropilesCompressionProvider