import React, { useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { withDefaultVerticalCapacityChart } from '../../../../../hoc';
import { isNumber, roundNumber, setSoilColor } from '../../../../../utils';
import stringify from 'fast-json-stable-stringify';
import useDeepCompareEffect from 'use-deep-compare-effect';

const TcdChart = ({
    dataToChart: { results = [], soilProfile = [] },
    chartOptions,
}) => {
    const [options, setOptions] = useState(chartOptions);
    const [series, setSeries] = useState([
        {
            name: '',
            data: [],
        },
    ]);

    const seriesStringify = stringify(series);

    useDeepCompareEffect(() => {
        setSeries(() => {
            return [
                {
                    name: 'Z',
                    data: [
                        ...results.reduce((acc, ele, index, arr) => {
                            if (index === arr.length - 1) {
                                return [...acc, [ele.tcd, ele.z], [0, ele.z]];
                            } else {
                                if (isNumber(ele.tcd1)) {
                                    return [
                                        ...acc,
                                        [ele.tcd, ele.z],
                                        [ele.tcd1, ele.z],
                                    ];
                                } else {
                                    return [...acc, [ele.tcd, ele.z]];
                                }
                            }
                        }, []),
                    ],
                },
            ];
        });

        setOptions(() => {
            const maxZ = Math.max(...results.map((ele) => ele.z));

            return {
                ...chartOptions,
                yaxis: {
                    ...chartOptions['yaxis'],
                    type: 'numeric',
                    tickAmount: Math.ceil(maxZ / 2),
                    max: maxZ,
                },
                xaxis: {
                    ...chartOptions['xaxis'],
                    max:
                        1.1 *
                        Math.max(
                            ...results.reduce(
                                (acc, ele) => [...acc, ele.tcd],
                                [],
                            ),
                        ),
                },
                fill: {
                    ...chartOptions['fill'],
                    colors: ['#333'],
                    opacity: 0.25,
                },
                stroke: {
                    ...chartOptions['stroke'],
                    width: 1,
                    colors: ['#666'],
                },
                tooltip: {
                    ...chartOptions['tooltip'],
                    custom: function ({
                        series,
                        seriesIndex,
                        dataPointIndex,
                        w,
                    }) {
                        return `<div>
                                    Z: ${roundNumber(
                                        series[seriesIndex][dataPointIndex],
                                        2,
                                    )} [m.p.p.t]
                                </div>
                                <div>
                                    Qcd: ${roundNumber(
                                        w.globals.initialSeries[0].data[
                                            dataPointIndex
                                        ][0],
                                        2,
                                    )} [kPa]
                                </div>
                            `;
                    },
                },
                annotations: {
                    ...chartOptions['annotations'],

                    yaxis: [
                        ...soilProfile.reduce((acc, ele) => {
                            return [
                                ...acc,
                                {
                                    y: ele.height - ele.absHeight,
                                    y2: ele.height,
                                    borderColor: 'transparent',
                                    strokeDashArray: 0,
                                    opacity: 0.15,
                                    fillColor: setSoilColor({
                                        id: ele.density,
                                        il: ele.plasticity,
                                    }),
                                },
                            ];
                        }, []),
                    ],
                },
            };
        });
    }, [chartOptions, results, soilProfile]);

    return (
        <Chart
            options={options}
            series={useMemo(
                () => JSON.parse(seriesStringify),
                [seriesStringify],
            )}
            type="area"
        />
    );
};

export default React.memo(
    withDefaultVerticalCapacityChart(TcdChart),
    (prev, next) => stringify(prev) === stringify(next),
);
