const setSoilColorQc = ({ type, qc }) => {
    if(type  === 0){
      if(qc >= 0 && qc < 3){
        return '#ffdf00'
      } else if(qc >= 3 && qc < 7){
        return '#ffd300'
      } else if(qc >= 7 && qc < 12){
        return '#ffcc00'
      } else if(qc >= 12 && qc < 15){
        return '#daa520'
      } else if(qc >= 15){
        return '#b8860b'
      } else {
        return '#b8860b' 
      }     
    } else if(type === 1){

        return '#059033'

    } else {
      return '#007f66'
    }
  }
  
    export default setSoilColorQc