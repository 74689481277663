import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { roundNumber } from '../../utils';
import { useWindowSize } from '../../hooks';
import useDeepCompareEffect from 'use-deep-compare-effect';

const Wrapper = styled.div`
    .apexcharts-svg {
        border-radius: 5px;
    }
    .apexcharts-menu-item {
        color: #343a40;
        font-size: 14px;
    }
    .apexcharts-tooltip {
        padding: 5px;
        color: #343a40; 
        font-size: 14px;
    }
    .apexcharts-menu-icon {
        &:hover {
            & svg {
                fill: #6e8192 !important;
            }
        }
    }
`;

const withDefaultVerticalCapacityChart = (Component) => {
    return ({ chartTitle, yLabel, xLabel, dataToChart = {}, ...rest }) => {
        const windowSize = useWindowSize();
        const [loaded, setLoaded] = useState(false);

        const [options, setOptions] = useState({
            chart: {
                toolbar: {
                    offsetY: 5,
                },
                fontFamily: 'Abhaya Libre, Sans-serif',
                animations: {
                    enabled: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: true,
                offsetY: 0,
                offsetX: 10,
                background: {
                    enabled: false,
                },
                formatter: function (x, opt) {
                    const label =
                        opt.w.globals.initialSeries[0].data[
                            opt.dataPointIndex
                        ][0];

                    return label === 0 ? '' : roundNumber(label, 1);
                },
                style: {
                    fontSize: '14px',
                    colors: ['#000'],
                },
            },
            stroke: {
                width: 1,
                curve: 'straight',
            },
            fill: {
                opacity: 0.5,
                type: 'solid',
            },
            markers: {
                size: 3,
                hover: {
                    size: 5,
                },
                showNullDataPoints: false,
            },
            tooltip: {
                enabled: true,
                shared: false,
                intersect: true,
            },
            subtitle: {
                margin: 10,
                align: 'center',
                floating: true,
                style: {
                    fontSize: '15px',
                },
            },
            title: {
                text: chartTitle,
                offsetX: 3,
                offsetY: 3,
                margin: 15,
                align: 'center',
                style: {
                    fontSize: '16px',
                },
            },
            annotations: {
                position: 'back',
            },
            grid: {
                show: true,
            },
            yaxis: {
                reversed: true,
                type: 'numeric',
                title: {
                    text: yLabel,
                    offsetX: 3,
                    style: {
                        fontSize: '15px',
                    },
                },
                labels: {
                    minWidth: 50,
                    offsetX: -20,
                    style: {
                        fontSize: '14px',
                    },
                },
            },
            xaxis: {
                type: 'numeric',
                tickAmount: 8,
                axisBorder: {
                    show: false,
                },
                tickPlacement: 'between',
                title: {
                    text: xLabel,
                    offsetY: -5,
                    style: {
                        fontSize: '15px',
                    },
                },
                labels: {
                    offsetY: -10,
                    style: {
                        fontSize: '14px',
                    },
                },
            },
        });

        useEffect(() => {
            setOptions((state) => {
                return {
                    ...state,
                    xaxis: {
                        ...state['xaxis'],
                        labels: {
                            ...state['xaxis']['labels'],
                            formatter: (val) => val.toFixed(0),
                        },
                    },
                    yaxis: {
                        ...state['yaxis'],
                        labels: {
                            ...state['yaxis']['labels'],
                            formatter: function (val) {
                                return roundNumber(val, 1);
                            },
                        },
                    },
                };
            });
        }, []);

        useEffect(() => {
            const isWindowXs = windowSize.width < 576;
            setOptions((state) => {
                return {
                    ...state,
                    xaxis: {
                        ...state['xaxis'],
                        labels: {
                            ...state['xaxis']['labels'],
                            show: isWindowXs ? false : true,
                        },
                    },
                    yaxis: {
                        ...state['yaxis'],
                        show: isWindowXs ? false : true,
                    },
                    title: {
                        ...state['title'],
                        text: isWindowXs ? undefined : chartTitle,
                    },
                    subtitle: {
                        ...state['subtitle'],
                        offsetY: isWindowXs ? 3 : 35,
                    },
                };
            });
        }, [windowSize.width, chartTitle]);

        useDeepCompareEffect(() => {
            setLoaded(false);
            const timeout = setTimeout(() => {
                setLoaded(true);
            }, 0);

            return () => clearTimeout(timeout);
        }, [options]);

        return (
            <Wrapper>
                {loaded && (
                    <Component
                        chartOptions={options}
                        dataToChart={dataToChart}
                        {...rest}
                    />
                )}
            </Wrapper>
        );
    };
};

export default withDefaultVerticalCapacityChart;
